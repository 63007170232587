var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import InfosetFiltersHeaderMenu from '@/modules/infosets/components/InfosetFiltersHeaderMenu.vue';
let InfosetHeader = class InfosetHeader extends Vue {
    constructor() {
        super(...arguments);
        this.searchString = '';
        this.listMode = this.viewModeSelected;
    }
    changeListMode(value) {
        this.listMode = value;
        return this.listMode;
    }
    searchCompanies(term) {
        this.searchString = term;
        return term;
    }
};
__decorate([
    Prop({ default: '' })
], InfosetHeader.prototype, "title", void 0);
__decorate([
    Prop({ default: '' })
], InfosetHeader.prototype, "memberName", void 0);
__decorate([
    Prop({ default: 'kanban' })
], InfosetHeader.prototype, "viewModeSelected", void 0);
__decorate([
    Prop({ default: false })
], InfosetHeader.prototype, "disabledUI", void 0);
__decorate([
    Prop({ default: '' })
], InfosetHeader.prototype, "companiesKanbanStats", void 0);
__decorate([
    Emit('changeListMode')
], InfosetHeader.prototype, "changeListMode", null);
__decorate([
    Emit('searchCompanies')
], InfosetHeader.prototype, "searchCompanies", null);
InfosetHeader = __decorate([
    Component({
        name: 'InfosetHeader',
        components: {
            Input,
            InfosetFiltersHeaderMenu,
        },
    })
], InfosetHeader);
export default InfosetHeader;
