var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CompanyDrawerHeader from '@/components/CompanyDrawerHeader.vue';
import CompanyDrawerApplets from '@/components/CompanyDrawerApplets.vue';
import CompanyDetailsBody from '@/components/CompanyDetailsBody.vue';
import { CompanyStatus } from '@/overmind/companies/state';
import { mixpanelIncrement, mixpanelTracking } from '@/services/mixpanel';
let CompanyDetails = class CompanyDetails extends Vue {
    constructor() {
        super(...arguments);
        this.tab = 'summary';
    }
    handleMixPanelTracking(value) {
        const replacements = {
            summary: 'resumo',
            register: 'registro',
            contact: 'contatos',
            decisors: 'decisores',
            'additional-data': 'dados adicionais',
        };
        return mixpanelTracking(`pre-crm: Clicou na aba ${replacements[value]}`);
    }
    closeSection() {
        this.$emit('closeSection');
    }
    getAdditionalData(tab) {
        this.$emit('getAdditionalData', tab);
    }
    get handleCompanyName() {
        return this.company
            ? this.company.fantasyName
                ? this.company.fantasyName
                : this.company.name
                    ? this.company.name
                    : ''
            : '';
    }
    get handleCompanyArea() {
        return this.company ? this.company.area : '';
    }
    getCompanyCnpjsNextPage(page) {
        this.$emit('getCompanyCnpjsNextPage', page);
    }
    mounted() {
        mixpanelTracking('pre-crm: Acessou informações de uma empresa');
        mixpanelIncrement('pre-crm: Acessou informações de uma empresa');
        this.handleMixPanelTracking(this.tab);
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyDetails.prototype, "company", void 0);
__decorate([
    Prop({ default: '' })
], CompanyDetails.prototype, "currentInfosetId", void 0);
__decorate([
    Prop({ default: '' })
], CompanyDetails.prototype, "currentCompanyId", void 0);
__decorate([
    Prop({ default: CompanyStatus.INITIAL })
], CompanyDetails.prototype, "companyStatus", void 0);
__decorate([
    Prop({ default: () => { } })
], CompanyDetails.prototype, "hyperbolicData", void 0);
__decorate([
    Watch('tab')
], CompanyDetails.prototype, "handleMixPanelTracking", null);
CompanyDetails = __decorate([
    Component({
        components: { CompanyDrawerHeader, CompanyDrawerApplets, CompanyDetailsBody },
    })
], CompanyDetails);
export default CompanyDetails;
