var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import Input from 'components/Input.vue';
let InfosetFiltersHeaderMenu = class InfosetFiltersHeaderMenu extends Vue {
    constructor() {
        super(...arguments);
        this.filterGroup = [
            {
                type: 'Checkbox',
                label: 'Status Prospecção',
                items: [
                    { label: 'Todos', value: false },
                    { label: 'Não iniciado', value: false },
                    { label: 'Contatados', value: false },
                    { label: 'Interessados', value: false },
                    { label: 'Sucesso', value: false },
                    { label: 'Descartados', value: false },
                ],
            },
            {
                type: 'InputCheckbox',
                label: 'Setores',
                items: [
                    { label: 'Agricultura e Agropecuária', value: false },
                    { label: 'Produção Florestal', value: false },
                    { label: 'Pesca e agricultura', value: false },
                    { label: 'Extração de carvão mineral', value: false },
                ],
            },
            {
                type: 'MultipleSlider',
                label: 'Faixa de Funcionários',
                items: [{ min: 0, max: 0 }],
            },
            {
                type: 'Checkbox',
                label: 'Categorização',
                items: [
                    { label: 'Todos', value: false },
                    { label: 'Categoria 1', value: false },
                    { label: 'Categoria 2', value: false },
                    { label: 'Categoria 3', value: false },
                ],
            },
            {
                type: 'MultipleSlider',
                label: 'Temperatura',
                items: [{ min: 0, max: 0 }],
            },
            {
                type: 'Checkbox',
                label: 'Status do Telefone',
                items: [
                    { label: 'Todos', value: false },
                    { label: 'Válido', value: false },
                    { label: 'Não atende', value: false },
                    { label: 'Não existe', value: false },
                    { label: 'A validar', value: false },
                ],
            },
            {
                type: 'TextInput',
                label: 'Palavras-chave',
                items: '',
            },
        ];
        this.sliderEmployees = ['5', '50'];
        this.sliderTemperature = ['20', '60'];
    }
};
InfosetFiltersHeaderMenu = __decorate([
    Component({
        name: 'InfosetFiltersHeaderMenu',
        components: {
            Input,
        },
    })
], InfosetFiltersHeaderMenu);
export default InfosetFiltersHeaderMenu;
