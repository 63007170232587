var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import HeaderContainer from '@/modules/infosets/containers/InfosetHeader.container.vue';
import CompanyDetailsContainer from '@/modules/infosets/containers/CompanyDetails.container.vue';
import CompaniesTable from 'modules/infosets/components/CompaniesTable.vue';
import InfosetNotFound from 'modules/infosets/components/InfosetNotFound.vue';
import CompaniesKanbanContainer from '@/modules/infosets/containers/CompaniesKanban.container.vue';
import CompaniesEmpty from 'modules/infosets/components/CompaniesEmpty.vue';
import Pagination from 'components/Pagination.vue';
import { connect } from '@/overmind';
import { isEmpty } from 'lodash';
import { mixpanelTracking } from '@/services/mixpanel';
let InfosetContainer = class InfosetContainer extends Vue {
    constructor() {
        super(...arguments);
        this.curPage = 1;
        this.showCompanyDrawer = false;
        this.loadingStatus = false;
        this.searchNotFound = false;
        this.animateStatus = '';
    }
    getCompaniesByPage(companies) {
        // @ts-ignore TS2349: property inexistent
        const infosetId = this.currentInfosetId
            ? // @ts-ignore TS2349: property inexistent
                // @ts-ignore TS2349: property inexistent
                this.currentInfosetId
            : this.$route.params;
        if (infosetId) {
            if (Array.isArray(companies) && companies.length > 0) {
                return companies;
            }
            else {
                return [];
            }
        }
        return [];
    }
    emptyCompanies() {
        if (
        // @ts-ignore
        !isEmpty(this.searchTerm) &&
            // @ts-ignore
            isEmpty(this.companies) &&
            // @ts-ignore
            isEmpty(this.companiesKanban)) {
            this.searchNotFound = true;
        }
        else {
            this.searchNotFound = false;
        }
        // @ts-ignore
        return isEmpty(this.companies) && isEmpty(this.companiesKanban);
    }
    changePage(pageNumber) {
        setTimeout(() => {
            // @ts-ignore TS2349: property inexistent
            this.curPage = this.page;
        }, 500);
        const { infosetId } = this.$route.params;
        //this.actions.fetchAllInfosets({ limit: this.limit, page: pageNumber });
        // @ts-ignore TS2349: property inexistent
        if (pageNumber !== this.page) {
            // @ts-ignore TS2349: property inexistent
            this.actions.setPage(pageNumber);
            // @ts-ignore TS2349: property inexistent
            this.actions.getCompaniesByInfosetID({
                infosetId,
                // @ts-ignore TS2349: property inexistent
                limit: this.limit,
                page: pageNumber,
                // @ts-ignore TS2349: property inexistent
                query: this.searchTerm,
            });
        }
    }
    async changeCompanyStatus(data) {
        const { infosetId } = this.$route.params;
        this.animateStatus = data.status;
        // @ts-ignore TS2349: property inexistent
        await this.actions.updateCompanyStatus({
            infosetId,
            companyId: data.id,
            status: data.status,
        });
        this.animateStatus = '';
        // @ts-ignore TS2349: property inexistent
        await this.actions.getMetricsToInfoset({ infosetId });
    }
    async fetchCompaniesStatus() {
        const { infosetId } = this.$route.params;
        const { view_as: viewAs } = this.$route.query;
        this.loadingStatus = true;
        // @ts-ignore TS2349: property inexistent
        await this.actions.getCompaniesStatus({ infosetId, viewAs });
        this.loadingStatus = false;
    }
    async initData(infosetId) {
        // @ts-ignore TS2349: property inexistent
        this.actions.getCompaniesByInfosetID({
            infosetId,
            // @ts-ignore TS2349: property inexistent
            limit: this.limit,
            page: 1,
        });
        // @ts-ignore TS2349: property inexistent
        if (isEmpty(this.currentInfoset)) {
            // @ts-ignore TS2349: property inexistent
            await this.getInfosetByID(infosetId);
        }
    }
    changeRoute(value) {
        if (value) {
            // @ts-ignore TS2349: property inexistent
            this.setCurrentInfosetByID(value.params.infosetId);
            if (value.query.page) {
                if (value.query.page == 0) {
                    this.changePage(1);
                    return;
                }
                this.changePage(Number(value.query.page));
            }
            return;
        }
        this.changePage(1);
        this.init();
    }
    async init() {
        const { infosetId } = this.$route.params;
        // @ts-ignore TS2349: property inexistent
        await this.actions.setCurrentInfosetByID(infosetId);
        this.initData(infosetId);
        this.$gtag.pageview({ page_path: this.$route.path });
    }
    // tracking
    handleMixpanelTracking(text) {
        return mixpanelTracking(text);
    }
    // end
    mounted() {
        this.init();
        if (this.$route.query) {
            this.changeRoute(this.$route);
        }
        this.handleMixpanelTracking('pre-crm: Acessou o pré-CRM');
    }
    beforeDestroy() {
        // @ts-ignore TS2349: property inexistent
        this.actions.cleanStore();
        this.handleMixpanelTracking('pre-crm: Saiu do pré-CRM');
    }
};
__decorate([
    Watch('$route')
], InfosetContainer.prototype, "changeRoute", null);
InfosetContainer = __decorate([
    Component(connect(({ state: { companies: state, infosets: infosets }, actions }) => ({
        companies: state.companies,
        companiesKanban: state.companiesKanban,
        companiesKanbanStats: state.companiesKanbanStats,
        companiesInPage: state.companiesInPage,
        filteredCompanies: state.filteredCompanies,
        totalPages: state.totalPages,
        page: state.page,
        limit: state.limit,
        searchTerm: state.searchTerm,
        status: state.status,
        viewMode: state.viewMode,
        currentInfosetId: state.currentInfosetId,
        currentCompanyId: state.currentCompanyId,
        actions: actions.companies,
        infosets: infosets,
        setCurrentInfosetByID: actions.infosets.setCurrentInfosetByID,
        getInfosetByID: actions.infosets.fetchInfosetByID,
        currentInfoset: infosets.currentInfoset,
        infosetStatus: infosets.infosetStatus,
    }), {
        name: 'InfosetContainer',
        components: {
            HeaderContainer,
            CompanyDetailsContainer,
            Pagination,
            CompaniesTable,
            CompaniesKanbanContainer,
            CompaniesEmpty,
            InfosetNotFound,
        },
    }))
], InfosetContainer);
export default InfosetContainer;
