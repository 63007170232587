var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CompaniesKanban from 'modules/infosets/components/CompaniesKanban.vue';
import { CompaniesKanbanStatus } from '@/overmind/companies/state';
import { connect } from '@/overmind';
import { json } from 'overmind';
import { isEmpty } from 'lodash';
let CompaniesKanbanContainer = class CompaniesKanbanContainer extends Vue {
    constructor() {
        super(...arguments);
        this.disposeCompaniesReaction = () => { };
        // @ts-ignore TS2349: property inexistent
        this.companies = json(this.companiesKanban);
        this.status = 'not_started';
        this.page = 1;
        this.loading = {
            not_started: true,
            started: true,
            interested: true,
            success: true,
            discarded: true,
            future_interest: true,
        };
        this.searchTermCompanies = '';
    }
    onChangeSearchTerm(currTerm) {
        this.searchTermCompanies = currTerm;
    }
    async kanbanLoadMore({ status, page, query, }) {
        this.loading[status] = true;
        // @ts-ignore TS2349: property inexistent
        const infosetId = this.currentInfosetId
            ? // @ts-ignore TS2349: property inexistent
                // @ts-ignore TS2349: property inexistent
                this.currentInfosetId
            : this.$route.params.infosetId;
        const { view_as: viewAs } = this.$route.query;
        // @ts-ignore TS2349: property inexistent
        const { status: s, page: p } = await this.getCompaniesForKanban(infosetId, status, page, viewAs, query);
        this.status = s;
        this.page = p;
        this.loading[status] = false;
    }
    async changeStatus(data) {
        const { infosetId } = this.$route.params;
        const { view_as: viewAs } = this.$route.query;
        //registrando evento no tag manager
        //@ts-ignore
        window.dataLayer.push({ event: 'movimentou_kanban' });
        this.$emit('mixtracking', `pre-crm: Companhia movimentada para ${this.convertStatusToTracking(data.status)}`);
        if (!viewAs) {
            // @ts-ignore TS2349: property inexistent
            await this.actions.updateCompanyStatus({
                infosetId,
                companyId: data.id,
                status: data.status,
            });
            // @ts-ignore TS2349: property inexistent
            await this.actions.getMetricsToInfoset({ infosetId });
        }
    }
    convertStatusToTracking(status) {
        switch (status.toLowerCase()) {
            case 'not_started':
                return 'Não contatado';
            case 'started':
                return 'Contatado';
            case 'interested':
                return 'Interessado';
            case 'success':
                return 'Sucesso';
            case 'discarded':
                return 'Descartado';
            case 'future_interest':
                return 'Futuro Interesse';
            default:
                return status;
        }
    }
    async getCompaniesForKanban(infosetId, status, page, viewAs, query) {
        // @ts-ignore TS2349: property inexistent
        return await this.actions.getCompaniesForKanban({
            infosetId,
            status,
            page,
            viewAs,
            query,
        });
    }
    async mounted() {
        const { infosetId } = this.$route.params;
        const { view_as: viewAs } = this.$route.query;
        this.loading.not_started = true;
        this.loading.started = true;
        this.loading.interested = true;
        this.loading.success = true;
        this.loading.discarded = true;
        this.loading.future_interest = true;
        if (isEmpty(this.companies)) {
            // @ts-ignore TS2349: property inexistent
            this.actions.setCompaniesKanbanState(CompaniesKanbanStatus.RECEIVING);
            // @ts-ignore TS2349: property inexistent
            const not_started = await this.getCompaniesForKanban(infosetId, 'not_started', 1, viewAs, this.searchTermCompanies);
            // @ts-ignore TS2349: property inexistent
            const started = await this.getCompaniesForKanban(infosetId, 'started', 1, viewAs, this.searchTermCompanies);
            // @ts-ignore TS2349: property inexistent
            const interested = await this.getCompaniesForKanban(infosetId, 'interested', 1, viewAs, this.searchTermCompanies);
            // @ts-ignore TS2349: property inexistent
            const success = await this.getCompaniesForKanban(infosetId, 'success', 1, viewAs, this.searchTermCompanies);
            // @ts-ignore TS2349: property inexistent
            const discarded = await this.getCompaniesForKanban(infosetId, 'discarded', 1, viewAs, this.searchTermCompanies);
            // @ts-ignore TS2349: property inexistent
            const future_interest = await this.getCompaniesForKanban(infosetId, 'future_interest', 1, viewAs, this.searchTermCompanies);
            if (not_started &&
                started &&
                interested &&
                success &&
                discarded &&
                future_interest)
                // @ts-ignore TS2349: property inexistent
                this.actions.setCompaniesKanbanState(CompaniesKanbanStatus.RECEIVED);
        }
        setTimeout(() => {
            this.loading.not_started = false;
            this.loading.started = false;
            this.loading.interested = false;
            this.loading.success = false;
            this.loading.discarded = false;
            this.loading.future_interest = false;
        }, 1000);
    }
};
__decorate([
    Prop({ default: '' })
], CompaniesKanbanContainer.prototype, "searchTerm", void 0);
__decorate([
    Prop({ default: '' })
], CompaniesKanbanContainer.prototype, "companiesKanbanStats", void 0);
__decorate([
    Prop({ default: '' })
], CompaniesKanbanContainer.prototype, "currentCompanyId", void 0);
__decorate([
    Watch('searchTerm', { immediate: true })
], CompaniesKanbanContainer.prototype, "onChangeSearchTerm", null);
CompaniesKanbanContainer = __decorate([
    Component(connect(({ state: { companies: state }, actions }) => ({
        companiesKanban: state.companiesKanban,
        currentInfosetId: state.currentInfosetId,
        statsMetrics: state.statsMetrics,
        actions: actions.companies,
    }), {
        name: 'CompaniesKanbanContainer',
        components: {
            CompaniesKanban,
        },
        mounted() {
            this.disposeCompaniesReaction = this.overmind.reaction(({ companies: state }) => state.companiesKanban, companiesKanban => {
                this.companies = json(companiesKanban);
            }, { nested: true });
        },
        beforeDestroy() {
            this.disposeCompaniesReaction();
            this.actions.cleanCompaniesKanban();
        },
    }))
], CompaniesKanbanContainer);
export default CompaniesKanbanContainer;
