var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, PropSync, Emit, Prop } from 'vue-property-decorator';
let CompanyTableRow = class CompanyTableRow extends Vue {
    constructor() {
        super(...arguments);
        this.statusOptions = [
            'Contatada',
            'Não contatada',
            'Interessada',
            'Sucesso',
            'Descartada',
            'Interesse Futuro',
        ];
    }
    get viewingAsMember() {
        return !!this.$route.query.view_as;
    }
    changeStatus(value) {
        return {
            id: this.companyItem.id,
            status: this.extractValue(value),
        };
    }
    extractValue(item) {
        switch (item) {
            case 'Não contatada':
                return 'not_started';
            case 'Contatada':
                return 'started';
            case 'Interessada':
                return 'interested';
            case 'Sucesso':
                return 'success';
            case 'Descartada':
                return 'discarded';
            case 'Interesse Futuro':
                return 'future_interest';
            default:
                return '';
        }
    }
    formatStatusValue(value) {
        switch (value) {
            case 'not_started':
                return 'Não contatada';
            case 'started':
                return 'Contatada';
            case 'interested':
                return 'Interessada';
            case 'success':
                return 'Sucesso';
            case 'discarded':
                return 'Descartada';
            case 'future_interest':
                return 'Interesse Futuro';
            default:
                return '';
        }
    }
    getCompanyEmployeesCount(employeesData) {
        return employeesData.cnpj || employeesData.company || 0;
    }
    cannotSelect(item) {
        switch (this.companyItem.status) {
            case 'not_started':
                return item === 'Não contatada' ? true : false;
            case 'started':
                return item === 'Contatada' ? true : false;
            case 'interested':
                return item === 'Interessada' ? true : false;
            case 'success':
                return item === 'Sucesso' ? true : false;
            case 'future_interest':
                return item === 'Interesse Futuro' ? true : false;
            case 'discarded':
                return item === 'Descartada' ? true : false;
            default:
                return false;
        }
        //return item === this.companyItem.status ? false : true;
    }
    goToCompanyDetail() {
        //this.$parent.$emit('openCompanyDetail', true, this.companyItem.id);
        const { view_as: viewAs } = this.$route.query;
        if (viewAs) {
            this.$router
                .push({
                name: 'infoset_company',
                params: { companyId: this.companyItem.id },
                query: { view_as: viewAs },
            })
                .catch(e => {
                if (this.$parent)
                    this.$parent.$emit('openCompanyDetail', true);
            });
        }
        else {
            this.$router
                .push({
                name: 'infoset_company',
                params: { companyId: this.companyItem.id },
                query: { page: this.curPage.toString() },
            })
                .catch(e => {
                if (this.$parent)
                    this.$parent.$emit('openCompanyDetail', true);
            });
        }
        // this.$parent.$parent.$emit("openCompanyDetail", true);*/
    }
};
__decorate([
    PropSync('company', Object)
], CompanyTableRow.prototype, "companyItem", void 0);
__decorate([
    Prop({ default: false })
], CompanyTableRow.prototype, "loadingStatus", void 0);
__decorate([
    Prop({ default: () => { } })
], CompanyTableRow.prototype, "curPage", void 0);
__decorate([
    Emit('changeStatus')
], CompanyTableRow.prototype, "changeStatus", null);
CompanyTableRow = __decorate([
    Component
], CompanyTableRow);
export default CompanyTableRow;
