var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import InfosetContainer from '@/modules/infosets/containers/Infoset.container.vue';
import ContactAndSalesInfoModal from '@/components/ContactAndSalesInfoModal/ContactAndSalesInfoModal.vue';
import ReactivationAndReengagementModal from '@/components/ReactivationAndReengagementModal/ReactivationAndReengagementModal.vue';
let Infoset = class Infoset extends Vue {
    handleJivoChatVisibility(visible) {
        const jivoDiv = document.getElementsByTagName('jdiv')[1];
        if (jivoDiv) {
            if (visible)
                jivoDiv.classList.add('hidden');
            else
                jivoDiv.classList.remove('hidden');
        }
    }
    created() {
        this.handleJivoChatVisibility(true);
    }
    beforeDestroy() {
        this.handleJivoChatVisibility(false);
    }
};
Infoset = __decorate([
    Component({
        name: 'Infosets',
        components: {
            InfosetContainer,
            ContactAndSalesInfoModal,
            ReactivationAndReengagementModal,
        },
    })
], Infoset);
export default Infoset;
