var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Watch } from 'vue-property-decorator';
import CompanyDetails from '@/modules/infosets/components/CompanyDetails.vue';
import { connect } from '@/overmind';
import { formatCnpj, getWidthByScreen } from '@/shared/utils/helpers';
import { isEmpty } from 'lodash';
let CompanyDetailsContainer = class CompanyDetailsContainer extends Vue {
    constructor() {
        super(...arguments);
        this.showCompanyDetail = false;
    }
    beforeCloseCompanyDetail() {
        const { infosetId: id } = this.$route.params;
        const { view_as: viewAs } = this.$route.query;
        this.showCompanyDetail = false;
        // @ts-ignore TS2349: property inexistent
        this.companiesActions.resetCurrentCompany();
        if (id) {
            if (viewAs) {
                this.$router.replace({
                    path: `/infosets/${id}`,
                    query: { view_as: viewAs },
                });
            }
            else
                this.$router.replace({ path: `/infosets/${id}` });
        }
        else {
            this.$router.replace({
                // @ts-ignore TS2349: property inexistent
                path: `/infosets/${this.companiesState.currentInfosetId}`,
            });
        }
    }
    getAdditionalData(tab) {
        // @ts-ignore TS2349: property inexistent
        this.companiesActions.setHyperbolicData({ nodes: [], links: [] });
        if (tab === 'additional-data') {
            // @ts-ignore TS2349: property inexistent
            if (this.companiesState.currentCompany) {
                if (
                // @ts-ignore TS2349: property inexistent
                this.companiesState.currentCompany.badges &&
                    // @ts-ignore TS2349: property inexistent
                    this.companiesState.currentCompany.badges
                        .hyperbolictree_size_estimation > 1) {
                    // @ts-ignore TS2349: property inexistent
                    this.companiesActions.getCompanyHyperbolicData(
                    // @ts-ignore TS2349: property inexistent
                    { cnpj: this.companiesState.currentCompany.cnpj });
                }
            }
        }
    }
    async onRoute(to, from) {
        if (to.params.companyId && to.params.infosetId) {
            this.showCompanyDetail = true;
            // @ts-ignore TS2349: property inexistent
            this.companiesActions.setCurrentCompanyByID(to.params.companyId);
            // @ts-ignore TS2349: property inexistent
            this.companiesActions.setCurrentInfosetByID(to.params.infosetId);
        }
    }
    async onShowCompanyDetailChanged(val, oldVal) {
        const { infosetId: id, companyId } = this.$route.params;
        if (val) {
            // @ts-ignore TS2349: property inexistent
            this.companiesActions.setCurrentCompanyByID(companyId);
            // @ts-ignore TS2349: property inexistent
            this.companiesActions.setCurrentInfosetByID(id);
        }
    }
    getWidth() {
        return getWidthByScreen();
    }
    loadedCurrentCompany() {
        // @ts-ignore TS2349: property inexistent
        return !isEmpty(this.currentCompany);
    }
    async getData(infosetId, companyId) {
        // @ts-ignore TS2349: property inexistent
        await this.companiesActions.getCompanyByID({
            infosetId: infosetId,
            companyId: companyId,
        });
    }
    beforeDestroy() {
        // @ts-ignore TS2349: property inexistent
        this.companiesActions.resetCurrentCompany();
    }
    async mounted() {
        const { infosetId: id, companyId } = this.$route.params;
        if (id && companyId) {
            this.showCompanyDetail = true;
            await this.getData(id, companyId);
            // @ts-ignore TS2349: property inexistent
            this.companiesActions.setCurrentCompanyByID(companyId);
            // @ts-ignore TS2349: property inexistent
            this.companiesActions.setCurrentInfosetByID(id);
        }
        this.$emit('mixtracking', 'pre-crm: Acessou informações da companhia');
    }
    async getCompanyCnpjsNextPage(page) {
        // @ts-ignore
        await this.companiesActions.getCompanyCnpjs({
            // @ts-ignore
            cnpj: formatCnpj(this.currentCompany.cnpj),
            page,
        });
    }
};
__decorate([
    Watch('$route')
], CompanyDetailsContainer.prototype, "onRoute", null);
__decorate([
    Watch('showCompanyDetail')
], CompanyDetailsContainer.prototype, "onShowCompanyDetailChanged", null);
CompanyDetailsContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        companiesState: state.companies,
        currentCompany: state.companies.currentCompany,
        currentCompanyId: state.companies.currentCompanyId,
        currentInfosetId: state.companies.currentInfosetId,
        infosetsState: state.infosets,
        companiesActions: actions.companies,
        companyStatus: state.companies.companyStatus,
        hyperbolicData: state.companies.hyperbolicData,
    }), { components: { CompanyDetails } }))
], CompanyDetailsContainer);
export default CompanyDetailsContainer;
