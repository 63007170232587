var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { isEmpty } from 'lodash';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
let PhonesProspectInfoDropdown = class PhonesProspectInfoDropdown extends Vue {
    validatePhone() {
        return true;
    }
    get validationButtonLabel() {
        return this.loading
            ? 'Solicitando...'
            : !this.canRequestValidation
                ? 'Validação solicitada'
                : 'Solicitar validação';
    }
    get canRequestValidation() {
        if (this.disableValidationPhone)
            return false;
        if (this.statusValidationsPhones === 'not initialized' ||
            this.statusValidationsPhones === 'finished') {
            return true;
        }
        return false;
    }
    disableValidationPhoneButton() {
        return (this.loading ||
            this.companies.length === 0 ||
            (!this.showPhonesValidationButton && !this.canRequestValidation));
    }
    get processedPhoneCount() {
        if (isEmpty(this.statsPhones))
            return 0;
        const sumAllPhones = this.validPhonesCount +
            this.invalidPhonesCount +
            this.notAttendedPhonesCount;
        return this.statsPhones.total_phones || sumAllPhones;
    }
    get validPhonesCount() {
        return this.statsPhones.valid_phones || 0;
    }
    get invalidPhonesCount() {
        return this.statsPhones.invalids_phones || 0;
    }
    get notAttendedPhonesCount() {
        return this.statsPhones.not_attended || 0;
    }
};
__decorate([
    Prop({ default: () => [] })
], PhonesProspectInfoDropdown.prototype, "companies", void 0);
__decorate([
    Prop({ default: () => { } })
], PhonesProspectInfoDropdown.prototype, "statsPhones", void 0);
__decorate([
    Prop({ default: false })
], PhonesProspectInfoDropdown.prototype, "loading", void 0);
__decorate([
    Prop({ default: false })
], PhonesProspectInfoDropdown.prototype, "disableValidationPhone", void 0);
__decorate([
    Prop({ default: false })
], PhonesProspectInfoDropdown.prototype, "showPhonesValidationButton", void 0);
__decorate([
    Prop({ default: '' })
], PhonesProspectInfoDropdown.prototype, "statusValidationsPhones", void 0);
__decorate([
    Emit('validatePhone')
], PhonesProspectInfoDropdown.prototype, "validatePhone", null);
PhonesProspectInfoDropdown = __decorate([
    Component({
        name: 'PhonesProspectInfoDropdown',
    })
], PhonesProspectInfoDropdown);
export default PhonesProspectInfoDropdown;
