var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { json } from 'overmind';
let CompaniesKanban = class CompaniesKanban extends Vue {
    constructor() {
        super(...arguments);
        this.pages = {
            not_started: 1,
            started: 1,
            interested: 1,
            success: 1,
            discarded: 1,
            future_interest: 1,
        };
        this.columnDiscarded = '';
        this.columnFutureInterest = '';
        this.stages = [
            'not_started',
            'started',
            'interested',
            'success',
            'discarded',
            'future_interest',
        ];
        this.stageDiscarded = ['discarded'];
        this.blocks = [];
        this.showDrawerKanban = false;
        this.noDiscarded = false;
        this.columnOrigin = '';
        this.columnsLoading = this.setColumnsLoading();
    }
    get navbarWidth() {
        return this.$q.screen.height <= 657 ? 600 : 350;
    }
    onResultChange(curr, old) {
        if (curr && curr.status && curr.page)
            this.pages[curr.status] = curr.page;
    }
    onChangeDrawer(val, oldVal) {
        if (val === true) {
            this.animateElement(this.columnDiscarded, 'slideInRight', 'slideOutRight');
            this.animateElement(this.columnFutureInterest, 'slideInRight', 'slideOutRight');
            // @ts-ignore TS2349: property inexistent
            this.columnDiscarded.classList.remove('hidden');
            // @ts-ignore TS2349: property inexistent
            this.columnFutureInterest.classList.remove('hidden');
        }
        else {
            this.animateElement(this.columnDiscarded, 'slideOutRight', 'slideInRight');
            this.animateElement(this.columnFutureInterest, 'slideOutRight', 'slideInRight');
            // @ts-ignore TS2349: property inexistent
            this.columnDiscarded.classList.add('hidden');
            // @ts-ignore TS2349: property inexistent
            this.columnFutureInterest.classList.add('hidden');
        }
    }
    onChangeCompanies(val, oldVal) {
        this.generateBlocks();
        this.columnsLoading = this.setColumnsLoading();
    }
    getStageName(stage) {
        switch (stage) {
            case 'not_started':
                return 'Não Contatada';
            case 'started':
                return 'Contatada';
            case 'interested':
                return 'Interessada';
            case 'success':
                return 'Sucesso';
            case 'discarded':
                return 'Descartada';
            case 'future_interest':
                return 'Futuro Interesse';
            default:
                return '';
        }
    }
    handleBadges(company, type) {
        if (company.badges === null) {
            return false;
        }
        else {
            const index = json(company.badges.available_data).findIndex(item => item === type);
            if (index !== -1) {
                return true;
            }
            else {
                return false;
            }
        }
    }
    goToCompanyDetail(companyId) {
        const { view_as: viewAs } = this.$route.query;
        if (viewAs) {
            this.$router
                .push({
                name: 'infoset_company',
                params: { companyId: companyId },
                query: { view_as: viewAs },
            })
                .catch(e => {
                if (this.$parent)
                    this.$parent.$emit('openCompanyDetail', true);
            });
        }
        else {
            this.$router
                .push({
                name: 'infoset_company',
                params: { companyId },
            })
                .catch(e => this.$emit('openCompanyDetail', true));
        }
    }
    getStageStats(stage) {
        return this.companies.filter(c => c.status === stage).length;
    }
    generateBlocks() {
        // @ts-ignore TS2349: property inexistent
        this.blocks = this.companies.map(company => {
            return {
                id: company.id,
                status: company.status,
                title: company.name,
                address: company.address,
                setor: company.cnae_informations,
                badges: json(company.badges),
            };
        });
    }
    emptyTile(stage) {
        return (this.blocks.filter((company) => company.status === stage)
            .length === 0);
    }
    dragstart(el, list, rest) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let self = this;
        this.columnOrigin = rest.getAttribute('data-status');
        if (!el.mousedown) {
            el.mousedown = (function (event) {
                function onMouseMove(event) {
                    el.hidden = true;
                    let elemBelow = document.elementsFromPoint(event.clientX, event.clientY);
                    el.hidden = false;
                    const opener = elemBelow.find(e => e.id === 'slider-opener');
                    if (opener) {
                        self.showDrawerKanban = !self.showDrawerKanban;
                    }
                    if (!elemBelow)
                        return;
                }
                function onMouseUp(event) {
                    document.removeEventListener('mousemove', onMouseMove);
                    el.onmouseup = null;
                }
                document.addEventListener('mousemove', onMouseMove);
                document.addEventListener('mouseup', onMouseUp);
            })();
        }
    }
    toggleDrawerKanban() {
        this.showDrawerKanban = !this.showDrawerKanban;
    }
    animateElement(element, effectToAdd, effectToRemove) {
        element.classList.add('animated', effectToAdd);
        element.classList.remove(effectToRemove);
    }
    setKanbanReadMode(value) {
        if (value) {
            const list = document.querySelectorAll('.drag-item');
            list.forEach(l => {
                l.setAttribute('draggable', 'false');
                // l.classList.add('no-pointer-events');
            });
        }
    }
    updated() {
        const { view_as: viewAs } = this.$route.query;
        if (viewAs)
            this.setKanbanReadMode(true);
    }
    updateStatus(id, newStatus) {
        this.columnsLoading = this.setColumnsLoading();
        const { view_as: viewAs } = this.$route.query;
        if (!viewAs) {
            // @ts-ignore TS2349: property inexistent
            this.blocks.find(b => b.id === id).status = newStatus;
            // @ts-ignore TS2349: property inexistent
            const columns = this.$refs.kanban.$refs.list;
            const actualColumn = columns.find(e => e.getAttribute('data-status') === this.columnOrigin);
            if (actualColumn.children.length === 0) {
                setTimeout(() => {
                    this.loadMore(this.columnOrigin, this.pages[this.columnOrigin], false);
                }, 200);
            }
            return {
                id,
                status: newStatus,
            };
        }
    }
    handleDiscardedTotal() {
        if (this.searchTerm)
            return this.blocks.filter(c => c.status === 'discarded' || c.status === 'future_interest').length;
        else
            return this.statsMetrics.discarded + this.statsMetrics.future_interest;
    }
    setScrollListeners() {
        if (this.$refs && this.$refs.kanban) {
            // @ts-ignore TS2349: property inexistent
            const list = this.$refs.kanban.$refs.list;
            list.forEach(column => {
                // @ts-ignore TS2349: property inexistent
                column.onscroll = () => {
                    // @ts-ignore TS2349: property inexistent
                    let isBottom = 
                    // @ts-ignore TS2349: property inexistent
                    column.scrollTop + column.offsetHeight + 50 >= column.scrollHeight;
                    // @ts-ignore TS2349: property inexistent
                    if (!this.columnsLoading[column.dataset.status]) {
                        if (isBottom) {
                            // @ts-ignore TS2349: property inexistent
                            this.loadMore(
                            // @ts-ignore TS2349: property inexistent
                            column.dataset.status, 
                            // @ts-ignore TS2349: property inexistent
                            this.pages[column.dataset.status]);
                        }
                    }
                };
            });
        }
    }
    setColumnsLoading() {
        if (this.stages)
            return this.stages.reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr]: false,
                };
            }, {});
        else
            return {};
    }
    loadingStage(stage) {
        return this.loadingStatuses[stage];
    }
    loadMore(status, page, getNextPage = true) {
        this.columnsLoading[status] = true;
        if (page === 0)
            this.pages[status] += 1;
        if (getNextPage)
            return { status, page: ++page, query: this.searchTerm };
        else
            return { status, page, query: this.searchTerm };
    }
    mounted() {
        this.generateBlocks();
        this.setScrollListeners();
        this.columnDiscarded = this.$refs.stage[4].offsetParent;
        this.columnFutureInterest = this.$refs.stage[5].offsetParent;
        // @ts-ignore TS2349: property inexistent
        this.columnDiscarded.classList.add('hidden');
        // @ts-ignore TS2349: property inexistent
        this.columnFutureInterest.classList.add('hidden');
        this.animateElement(this.columnDiscarded, 'slideOutRight', 'slideInRight');
        this.animateElement(this.columnFutureInterest, 'slideOutRight', 'slideInRight');
    }
};
__decorate([
    Prop({
        default: () => {
            //do nothing
        },
    })
], CompaniesKanban.prototype, "companies", void 0);
__decorate([
    Prop({ default: () => { } })
], CompaniesKanban.prototype, "statsMetrics", void 0);
__decorate([
    Prop({ default: '' })
], CompaniesKanban.prototype, "searchTerm", void 0);
__decorate([
    Prop({ default: () => { } })
], CompaniesKanban.prototype, "loadResult", void 0);
__decorate([
    Prop({ default: () => { } })
], CompaniesKanban.prototype, "loadingStatuses", void 0);
__decorate([
    Prop({ default: '' })
], CompaniesKanban.prototype, "companiesKanbanStats", void 0);
__decorate([
    Prop({ default: '' })
], CompaniesKanban.prototype, "currentCompanyId", void 0);
__decorate([
    Watch('loadResult')
], CompaniesKanban.prototype, "onResultChange", null);
__decorate([
    Watch('showDrawerKanban')
], CompaniesKanban.prototype, "onChangeDrawer", null);
__decorate([
    Watch('companies')
], CompaniesKanban.prototype, "onChangeCompanies", null);
__decorate([
    Emit('changeStatus')
], CompaniesKanban.prototype, "updateStatus", null);
__decorate([
    Emit('loadMore')
], CompaniesKanban.prototype, "loadMore", null);
CompaniesKanban = __decorate([
    Component({})
], CompaniesKanban);
export default CompaniesKanban;
