var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import InfosetFiltersHeader from 'modules/infosets/components/InfosetFiltersHeader.vue';
import CompaniesStatsHeader from 'modules/infosets/components/CompaniesStatsHeader.vue';
import { connect } from '@/overmind';
import { isEmpty } from 'lodash';
import { CompaniesKanbanStatus } from '@/overmind/companies/state';
let HeaderContainer = class HeaderContainer extends Vue {
    constructor() {
        super(...arguments);
        this.viewingAsMember = '';
        this.loadingValidate = false;
        this.disableValidationPhone = false;
        this.disableValidationEmail = false;
        this.validationSendingEmail = false;
    }
    async search(searchTerm) {
        const { infosetId } = this.$route.params;
        // @ts-ignore TS2349: property inexistent
        this.actions.setSearchTerm(searchTerm);
        // @ts-ignore TS2349: property inexistent
        this.actions.getCompaniesByInfosetID({
            infosetId,
            query: searchTerm,
        });
        if (searchTerm) {
            // @ts-ignore TS2349: property inexistent
            this.actions.setCompaniesKanbanClear();
            // @ts-ignore TS2349: property inexistent
            this.actions.setCompaniesKanbanState(CompaniesKanbanStatus.RECEIVING);
        }
        // @ts-ignore TS2349: property inexistent
        const not_started = await this.actions.getCompaniesForKanban({
            infosetId,
            status: 'not_started',
            page: 1,
            query: searchTerm,
        });
        // @ts-ignore TS2349: property inexistent
        const started = await this.actions.getCompaniesForKanban({
            infosetId,
            status: 'started',
            page: 1,
            query: searchTerm,
        });
        // @ts-ignore TS2349: property inexistent
        const interested = await this.actions.getCompaniesForKanban({
            infosetId,
            status: 'interested',
            page: 1,
            query: searchTerm,
        });
        // @ts-ignore TS2349: property inexistent
        const success = await this.actions.getCompaniesForKanban({
            infosetId,
            status: 'success',
            page: 1,
            query: searchTerm,
        });
        // @ts-ignore TS2349: property inexistent
        const discarded = await this.actions.getCompaniesForKanban({
            infosetId,
            status: 'discarded',
            page: 1,
            query: searchTerm,
        });
        // @ts-ignore TS2349: property inexistent
        const future_interest = await this.actions.getCompaniesForKanban({
            infosetId,
            status: 'future_interest',
            page: 1,
            query: searchTerm,
        });
        if (not_started &&
            started &&
            interested &&
            success &&
            discarded &&
            future_interest)
            // @ts-ignore TS2349: property inexistent
            this.actions.setCompaniesKanbanState(CompaniesKanbanStatus.RECEIVED);
    }
    infosetName() {
        // @ts-ignore TS2349: property inexistent
        if (this.currentInfoset)
            return this.currentInfoset.name;
        return '';
    }
    toggleListMode(value) {
        this.$emit('mixtracking', `pre-crm: habilitou modo ${value}`);
        // @ts-ignore TS2349: property inexistent
        this.actions.setViewMode(value);
    }
    getViewAsMemberName(infosetId) {
        const { view_as: viewAs } = this.$route.query;
        if (viewAs) {
            // @ts-ignore
            const members = this.currentInfoset.members;
            if (Array.isArray(members) && members.length > 0) {
                const member = members.find(member => member.id === viewAs);
                this.viewingAsMember = member.name;
            }
        }
    }
    handleValidationActionPermission() {
        // @ts-ignore TS2349: property inexistent
        if (!isEmpty(this.currentInfoset)) {
            // @ts-ignore TS2349: property inexistent
            if (this.currentInfoset.contacts === 0) {
                this.disableValidationPhone = true;
                this.disableValidationEmail = true;
            }
        }
    }
    async mounted() {
        const infosetId = await this.$route.params.infosetId;
        const { view_as: viewAs } = this.$route.query;
        this.handleValidationActionPermission();
        if (infosetId) {
            this.getViewAsMemberName(infosetId);
            await this.getAllMetrics(infosetId, viewAs);
            // @ts-ignore TS2349: property inexistent
            await this.actions.getStatusValidationsPhoneEmail({ infosetId });
        }
    }
    async getAllMetrics(infosetId, viewAs) {
        const reset = true;
        // @ts-ignore TS2349: property inexistent
        await this.actions.getMetricsToInfoset({ infosetId, reset, viewAs });
        // @ts-ignore TS2349: property inexistent
        await this.actions.getMetricsToPhones({ infosetId });
        // @ts-ignore TS2349: property inexistent
        await this.actions.getMetricsToEmails({ infosetId });
    }
    async validatePhone() {
        this.$emit('mixtracking', 'pre-crm: Solicitou validação de telefone');
        // @ts-ignore TS2349: property inexistent
        this.loadingValidate = true;
        this.disableValidationPhone = true;
        // @ts-ignore TS2349: property inexistent
        let infosetId = this.$route.params.infosetId;
        // @ts-ignore TS2349: property inexistent
        await this.actions.validatePhones({ infosetId });
        if (
        // @ts-ignore TS2349: property inexistent
        this.statusResponse.type === 'Phone-validation' &&
            // @ts-ignore TS2349: property inexistent
            this.statusResponse.code === 200) {
            this.loadingValidate = false;
        }
        else {
            this.loadingValidate = false;
        }
    }
    async validateEmails() {
        this.$emit('mixtracking', 'pre-crm: Solicitou validação de e-mails');
        // @ts-ignore TS2349: property inexistent
        this.loadingValidate = true;
        this.disableValidationEmail = true;
        // @ts-ignore TS2349: property inexistent
        let infosetId = this.$route.params.infosetId;
        // @ts-ignore TS2349: property inexistent
        await this.actions.validateEmails({ infosetId });
        if (
        // @ts-ignore TS2349: property inexistent
        this.statusResponse.type === 'Emails-validation' &&
            // @ts-ignore TS2349: property inexistent
            this.statusResponse.code === 200) {
            this.validationSendingEmail = true;
            this.loadingValidate = false;
        }
        else {
            this.loadingValidate = false;
        }
    }
};
__decorate([
    Prop({ default: '' })
], HeaderContainer.prototype, "animateStatus", void 0);
__decorate([
    Prop({ default: '' })
], HeaderContainer.prototype, "searchTerm", void 0);
__decorate([
    Prop({ default: '' })
], HeaderContainer.prototype, "companiesKanbanStats", void 0);
HeaderContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        disabledUI: state.companies.status.disabledUI,
        stats: state.companies.stats,
        viewMode: state.companies.viewMode,
        actions: actions.companies,
        currentInfoset: state.infosets.currentInfoset,
        currentInfosetId: state.companies.currentInfosetId,
        statsMetric: state.companies.statsMetrics,
        statsPhones: state.companies.statsPhones,
        statsEmails: state.companies.statsEmails,
        statusResponse: state.companies.statusResponse,
        phonesValidationsStatus: state.companies.phonesValidationsStatus,
        emailsValidationsStatus: state.companies.emailsValidationsStatus,
        noFoundInfoset: state.companies.status.noFound,
        showPhonesValidationButton: state.companies.showPhonesValidationButton,
        companies: state.companies.companies,
    }), {
        name: 'HeaderContainer',
        components: { InfosetFiltersHeader, CompaniesStatsHeader },
    }))
], HeaderContainer);
export default HeaderContainer;
